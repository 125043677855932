<template>
    <div style="width: 100%;background: white;">
      <SummaryNew :id="selectedIds" :domain="account_domain"/>
    </div>
</template>

<script>
import ApiServer from "./ApiServer";
import { JWT } from "node-jsonwebtoken";
import CryptoJS from 'crypto-js';
// import AddCustomer from "./AddCustomer.vue"
import moment from "moment";
import { createChart } from 'lightweight-charts';
import Multiselect from "vue-multiselect"
import LocalUtils from "../components/Utils/LocalUtils";
import SummaryNew from "./SummaryNew.vue";
// import CustomerLinkModal from "../components/CustomerLinkModal.vue";

export default {

    name: "Summary",
    data() {
        return {
            metabaseUrl:"",
            appeq_supported_application: '',
            account_domain: "",
            reason: "",
            reasonTime: "",
            trendsValue: false,
            metabaseNumber: 141,
            loadingTrends: false,
            barData: {},
            expanded: false,
            genieEnabled: true,
            dashboard: false,
            summaryData: [],
            org_id: "",
            i: 0,
            summaryIds: [],
            pulseData: [],
            chartDi: null,
            searchStr: "",
            barDi: null,
            lineData: {},
            demo: false,
            health: "RED",
            notesEnabled: true,
            slackEnabled: true,
            showMobile: false,
            showCustomerLinkModal: false,
            showSumm: true,
            lastUpdatedAtHighLights: '',
            showHighBtn: false,
            noDataFound: false,
            inputNoCustomer: '',
            trends: [],
            showMoreOptions: false,
            showMoreOptionsArray: [],
            showHighLights: false,
            disableHightLighBtn: false,
            highLights: "",
            loadingSum: false,
            selectedIds: '',
            selectedDom: {},
            loadingHighLights: false,
            showSummModal: false,
            showUserModal: false,
            viewModalOrCustomer: true,
            loggedIn: false,
            loadingHighLights: false
        }
    },
    components: {
        Multiselect,
        SummaryNew
    },

    created() {
        // window.addEventListener("message", async e => {
        //     console.log(e)
        //     switch (e.data.action) {
        //         case "__appeq__domain":
        //             if (e.data.fromFeeds) {
        //                 AppEQAnalytics.actionCall("SummaryViewed", "Inbox", { account: this.account_domain })

        //                 let email = localStorage.getItem('__email__id');
        //                 this.account_domain = e.data.value
        //                 this.health = 'RED'
        //                 ApiServer.getHealth(email, [e.data.value]).then(data => {
        //                     console.log(data, "HERE1")
        //                     this.health = data.data[0].health

        //                 });

        //             } else {
        //                 AppEQAnalytics.actionCall("SummaryViewed", "Inbox", { account_domain: this.account_domain })

        //                 this.account_domain = e.data.value.domain
        //                 this.health = e.data.value.health
        //                 if (e.data.demo) {
        //                     this.demo = true;
        //                 }
        //             }
        //             // this.showHighLights = true
        //             // const dt = await ApiServer.getAllQuestions(this.account_domain, 0)
        //             // console.log(dt)
        //             // if (dt.data && dt.data.length != 0) {
        //             //     this.highLights = dt.data[0]['answer'].replace(/[/*\\]/g, '')
        //             //     console.log(this.highLights,"---answer")
        //             // }
        //             // this.lastUpdatedAtHighLights = moment(e.data.value.lastUpdatedAt).format("DD MMM, YYYY")

        //             console.log("HERE2")
        //             await this.openSummary();
        //             break;

        //         case "__appeq__add_popup":
        //             this.showSumm = false;

        //             this.account_domain = e.data.value
        //             this.viewModalOrCustomer = false;
        //             break;
        //         case "__appeq__add_modal_popup":
        //             this.showSummModal = true;
        //             this.showSumm = false;
        //             if (e.data.value) {
        //                 console.log(e.data.value);
        //                 this.showUserModal = true;
        //             }
        //             this.viewModalOrCustomer = false;
        //             break;
        //     }

        // })
    },
    async mounted() {
        Intercom('update', {
  "hide_default_launcher": true
});
        console.log("Summary has started")
        const params=this.getParamsByKey()
        console.log(params)
        this.email=params['email']
        this.account_domain=params['domain']
        console.log("Summary has started2")
        await this.openSummary();
    await this.checkDash();
                         

        // this.loggedIn = localStorage.getItem("__email__id") ? true : false;
        this.loadingTrends = true;

    },
    methods: {
        async checkDash(){
            let data = await ApiServer.getSupportedApps();
            let features=data.data.configs.features
            features.map(async data => {
                if (data && data.feature_name == "appeqTrendsAndDashboard" && data.enabled == true) {
                                    this.dashboard = true;
                                    this.metabaseNumber = data.dashboardNum
                                   this.metabaseUrl= await this.opendashforAccounts()
                                }
            })
        },
         getParamsByKey() {
    const queryString = window.location.search.slice(1); // Remove the leading '?'
    const params = new URLSearchParams(queryString);

    const filteredParams = {};
    params.forEach((key,val) => {
        console.log(key,val)
            filteredParams[val] = key;
    });

    return filteredParams;
},

        openHealthAudit() {
            window.parent.postMessage({action:"__appeq_open_account_audits", data:{ account_domain: this.account_domain, id: this.selectedIds, type: "health" }},"*");
        },
        selectCustomer(d) {
            this.summaryIds = [d]
            this.selectedIds = d.id
            this.openSummary()
        },
        handleCloseCustomerLinkModal(e) {
            this.showCustomerLinkModal = false;
            this.openSummary()
        },
        async fetchNames() {
            if (this.inputNoCustomer) {
                const allowedNames = (await ApiServer.getNamesAccounts({ email: localStorage.getItem("__email__id"), search: this.inputNoCustomer })).data
                this.showMoreOptionsArray = allowedNames.data
                return
            }
            const allowedNames = (await ApiServer.getNamesAccounts({ email: localStorage.getItem("__email__id"), search: this.account_domain.slice(0, 3) })).data
            this.showMoreOptionsArray = allowedNames.data
        },
        async opendashforAccounts(){
            var METABASE_SITE_URL = "https://metabase.appeq.ai";
            var METABASE_SECRET_KEY = "0775a85bd18796da29602a47df4625236ed1bb4250584697b91d7fbc4b27e779";
            console.log("params")
            console.log(this.accountid)
            console.log(this.account_domain)
            console.log(this.metabaseNumber)
            const jwt = new JWT(METABASE_SECRET_KEY);
            const token = await jwt.sign({
                resource: { dashboard: parseInt(this.metabaseNumber) },
                params: {
                    domain: this.account_domain,
                    id:this.selectedIds
                },
                exp: Math.round(Date.now() / 1000) + (10 * 60)
            });
            var iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#theme=transparent&bordered=false&titled=false";
            // AppEQAnalytics.actionCall("DashboardOpened","Dashboard",{
            //     dashboard_num:this.metabaseNumber
            // })    
            return iframeUrl
            // feeds.sendActionToTop("__appeq_open_executive_dashboard", iframeUrl);

        },
        async openDash() {
            const userInfo = (await ApiServer.getUsersData(localStorage.getItem("__email__id"))).data
            this.org_id = userInfo.data.org_id
            var METABASE_SITE_URL = "https://metabase.appeq.ai";
            var METABASE_SECRET_KEY = "0775a85bd18796da29602a47df4625236ed1bb4250584697b91d7fbc4b27e779";
            const jwt = new JWT(METABASE_SECRET_KEY);
            let email = localStorage.getItem('__email__id')

            console.log(this.account_domain + "gauravyesy")
            const token = await jwt.sign({
                resource: { dashboard: this.metabaseNumber },
                params: {
                    domain: this.account_domain,
                },
                exp: Math.round(Date.now() / 1000) + (10 * 60)
            });
            var iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#theme=transparent&bordered=false&titled=false";

            window.parent.postMessage({action:"__appeq_open_executive_dashboard",data:iframeUrl},"*");
            // window.parent.postMessage({action:"__appeq_open_account_audits", data:{ account_domain: this.account_domain, id: this.selectedIds, type: "health" }});
            //

        },
        showTrends() {
            console.log(this.chartDi);
        },
        convertToDaysAgo(dateString) {
            const date = new Date(dateString);
            const currentDate = new Date();
            const timeDifference = currentDate - date;
            const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            return daysAgo + " days ago";
        },
        changeToPhoneSize() {
            this.showMobile = !this.showMobile;
            feeds.sendActionToTop("__appeq_minimize_summary", { state: this.showMobile });
        },
        changeToPcSize() {
            this.showMobile = false;
            feeds.sendActionToTop("__appeq_minimize_summary", { state: this.showMobile });
        },
        openTrends() {
            window.open("https://app.appeq.ai/summary-creator", "_blank");
        },
        openEditSummary() {
            const cred = {
                "__email__id": localStorage.getItem("__email__id")
            };
            const res = JSON.stringify(cred);
            const encryptedText = CryptoJS.AES.encrypt(res, "DYyAKzBo70ca9aCSLNgwzLutBhkw0EXs6Iqj7jI8").toString()
            console.log("val (sender):", encryptedText);
            const encodedToken = encodeURIComponent(encryptedText);
            let url = 'https://app.appeq.ai/summary-creator' + '/' + '?value=' + encodedToken;
            console.log(url);
            feeds.sendActionToTop("__appeq_open_summary_iframe", { 'url': url });
        },
        sendActionToTop(action,data){
            window.parent.postMessage({action:action,data:data},"*")
        },
        openNotes() {
            this.sendActionToTop("__appeq_open_notes_iframe_summary", { domain: this.account_domain, id: this.selectedIds, state: 'private' })
        },
        openPublicNotes() {
            this.sendActionToTop("__appeq_open_notes_iframe_summary", { domain: this.account_domain, id: this.selectedIds, state: 'public' })
        },
        openAddCustomer() {
            this.sendActionToTop("__appeq_open_popup_iframe_summary", { account_domain: this.account_domain });

        },
        openAudits() {
            this.sendActionToTop("__appeq_open_account_audits", { account_domain: this.account_domain, id: this.account_id });

        },
        redirectToPulse(data) {
            let final = { "account_domain": this.account_domain, "account_id": this.selectedIds }
            console.log(final);
            this.sendActionToTop("__appeq_open_ticket_iframe_pulse", final);
        },
        async openPulseInfo() {
            // let ids = await ApiServer.getSummaryIds(this.account_domain);
            if (this.selectedIds) {
                let id_ = this.selectedIds;
                let adminConfigs = localStorage.getItem('adminConfigs');
                if (adminConfigs) {
                    adminConfigs = JSON.parse(adminConfigs);
                    if (adminConfigs.business_object_name && adminConfigs.type && adminConfigs.type == 'pulse') {
                        let data = await ApiServer.fetchAllDataForOpen(adminConfigs.business_object_name, 0, this.account_domain, id_);
                        console.log(data);
                        if (data && data.data && data.data.data && data.data.data.data) {
                            this.pulseData = data.data.data.data;
                        }
                    } else {
                        let data = await ApiServer.readPulseData(this.account_domain, id_);
                        console.log(data);
                        if (data.data && Array.isArray(data.data) && data.data.length > 3) {
                            data.data = data.data.slice(0, 3)
                        }
                        this.pulseData = data.data;
                    }
                } else {
                    let data = await ApiServer.readPulseData(this.account_domain, id_);
                    console.log(data);
                    if (data.data && Array.isArray(data.data) && data.data.length > 3) {
                        data.data = data.data.slice(0, 3)
                    }
                    this.pulseData = data.data;
                }
            }
        },
        async changeTab(id) {
            this.loadingSum = true
            const crunchbaseData = {}
            console.log(id);
            console.log(this.selectedIds);
            // const crunchbaseData = (await ApiServer.getCrunchbaseData(this.account_domain)).data
            this.selectedIds = id
            const email = this.email;
            let data = (await ApiServer.fetchSummaryData(this.account_domain, this.selectedIds))
            console.log(data);

            if (data && data.length == 0) {
                this.noDataFound = true;
                this.loadingSum = false;
                return;
            }


            console.log(data)
            if (data.data && data.data[0].ai) {
                this.showHighBtn = true;
            }

            // check for members for which summary to be shown
            if (data && data.data && data.data[0].data)
                this.summaryData = data.data[0].data
            else {
                this.noDataFound = true;
                this.loadingSum = false;
                return;
            }

            // this.summaryData = data.data.data[0].data
            await Promise.all(this.summaryData.map(async res => {

                if (res.appeq_summary_row) {
                    await Promise.all(res.appeq_summary_row.map(async d => {

                        if (d.appeq_summary_column.appeq_summary_value) {
                            let val = d.appeq_summary_column.appeq_summary_value
                            let funcName = val.toString().substr(0, val.toString().indexOf("("));
                            val = val.toString().substr(val.toString().indexOf("("));

                            // d.appeq_summary_column.appeq_summary_bObj = val.split(",")[0].replace("(", "")
                            if (d.appeq_summary_column.appeq_summary_bObj == 'Crunchbase Data') {
                                console.log(val.split(",")[1].replace(")", "").includes('linkedin'), "gaurav")
                                if (val.split(",")[1].replace(")", "").includes('linkedin')) {
                                    if (crunchbaseData.entities[0].properties && crunchbaseData.entities[0].properties.linkedin && crunchbaseData.entities[0].properties.linkedin.value)
                                        d.appeq_summary_column.appeq_summary_value = crunchbaseData.entities[0].properties.linkedin.value
                                    else
                                        d.appeq_summary_column.appeq_summary_value = "No Data"

                                } else if (val.split(",")[1].replace(")", "").includes('website_url')) {
                                    d.appeq_summary_column.appeq_summary_value = crunchbaseData.entities[0].properties.homepage_url

                                } else if (val.split(",")[1].replace(")", "").includes('short_description')) {
                                    d.appeq_summary_column.appeq_summary_value = crunchbaseData.entities[0].properties.short_description

                                } else {
                                    d.appeq_summary_column.appeq_summary_value = crunchbaseData.entities[0].properties.location_identifiers.map(o => o.value).join(",")

                                }
                                return;
                            }
                            // d.appeq_summary_column.appeq_summary_value = (await ApiServer.fetchSummaryDataObj(this.account_domain, val.split(",")[0].replace("(", ""), funcName, val.split(",")[1].replace(")", ""))).data
                            if (d.appeq_summary_column.appeq_summary_value.error) {
                                d.appeq_summary_column.appeq_summary_value = 'No Data'
                                d.appeq_summary_column.appeq_summary_type = ''
                                return
                            }
                            d.appeq_summary_column.appeq_summary_value = d.appeq_summary_column.appeq_summary_value.data
                            if (d.appeq_summary_column.appeq_summary_type == 'graph' && !d.appeq_summary_column.appeq_summary_value.error && Array.isArray(d.appeq_summary_column.appeq_summary_value)) {
                                this.lineData['labels'] = d.appeq_summary_column.appeq_summary_value.map(data => data.date)
                                this.lineData['datasets'] = [{ 'data': d.appeq_summary_column.appeq_summary_value.map(data => data.tickets) }]
                                const ticketsData = d.appeq_summary_column.appeq_summary_value.map(data => {
                                    return { time: data.date.toString(), value: data.tickets }
                                })
                                this.lineData['data'] = ticketsData
                                return;
                            }
                            if (d.appeq_summary_column.appeq_summary_type == 'lastTicket' && d.appeq_summary_column.appeq_summary_value && d.appeq_summary_column.appeq_summary_value['created_at']) {
                                let date1 = new Date()
                                var x = new moment(d.appeq_summary_column.appeq_summary_value && d.appeq_summary_column.appeq_summary_value['created_at'] ? d.appeq_summary_column.appeq_summary_value['created_at'] : null)
                                var y = new moment(date1)
                                var duration = moment.duration(x.diff(y))
                                let duration_days = Math.abs(Math.floor(duration.asDays()))
                                d.appeq_summary_column.appeq_summary_prevalue = duration_days + ' days ago by '
                                d.appeq_summary_column.query = { 'id': d.appeq_summary_column.appeq_summary_value['id'] }
                                d.appeq_summary_column.appeq_emails = d.appeq_summary_column.appeq_summary_value['requester_email']
                                d.appeq_summary_column.appeq_summary_value = d.appeq_summary_column.appeq_summary_value['requester_email'].toString().split("@")[0][0]
                            }
                            if (d.appeq_summary_column.appeq_summary_type == 'requesters' && Array.isArray(d.appeq_summary_column.appeq_summary_value)) {
                                d.appeq_summary_column.appeq_emails = d.appeq_summary_column.appeq_summary_value
                                d.appeq_summary_column.appeq_summary_value = d.appeq_summary_column.appeq_summary_value.map(data => data.toString().split("@")[0].split(".").map(d => d[0]).join(""))
                            }
                            d.appeq_summary_column.appeq_summary_value = typeof d.appeq_summary_column.appeq_summary_value == "object" && d.appeq_summary_column.appeq_summary_type != "requesters" ? "_" : d.appeq_summary_column.appeq_summary_value;
                            if (d.appeq_summary_column.appeq_summary_label == 'Health') {
                                d.appeq_summary_column.appeq_summary_value = this.health;
                                console.log("health of it is", this.health);
                            }
                        }

                    })
                    )
                }
            }))
            this.loadingSum = false;

            await new Promise((res) => setTimeout(res, 200))
            if (this.lineData['data']) {
                const chart = createChart(document.getElementById("chartDiv"), {
                    width: 120, height: 80, grid: {
                        vertLines: {
                            color: "transparent",
                            visible: false
                        },
                        horzLines: {
                            color: "transparent"
                        },

                    },
                    leftPriceScale: {
                        visible: true,
                    },

                    timeScale: {
                        barSpacing: 9,
                    },

                });
                chart.priceScale('left').applyOptions({
                    autoScale: true,
                    visible: true,
                });
                chart.priceScale('right').applyOptions({
                    autoScale: true,
                    visible: false
                });
                const lineSeries = chart.addLineSeries();
                lineSeries.applyOptions({

                    lineType: 2, lineStyle: 0,
                    priceScale: {
                        position: 'left',

                    },


                })
                lineSeries.setData(this.lineData['data']);
                this.chartDi = chart
                if (this.highLights) {
                    this.disableHightLighBtn = true;
                    try {
                        let box = document.querySelector('#newSummaryDiv');
                        let widthh = box.offsetWidth - 10;
                        console.log(widthh);

                        var element = document.getElementById("highLights");
                        console.log(element);

                        element.setAttribute("style", `display:block;width:${widthh}px`);
                    } catch (e) {
                        console.log(e);
                    }
                    // await this.typeWriter(this.highLights)
                    this.disableHightLighBtn = false;
                }
            }
        },
        isURL(text) {
            const urlPattern = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})(\/\S*)?$/i;
            return urlPattern.test(text);
        }
        ,
        async handleClick() {

            try {
                let box = document.querySelector('#newSummaryDiv');
                let widthh = box.offsetWidth - 10;
                console.log(widthh);

                var element = document.getElementById("highLights");
                console.log(element);

                element.setAttribute("style", `display:block;width:${widthh}px`);
            } catch (e) {
                console.log(e);
            }

            this.disableHightLighBtn = true;
            this.loadingHighLights = true;
            this.showHighLights = true;
            document.getElementById("highLights").innerHTML = '';
            this.i = 0;
            const dt = await ApiServer.getAllQuestions(this.account_domain, 0)
            if (dt.data && dt.data.length != 0) {
                this.highLights = dt.data[0]['answer'].replace(/[/*\\]/g, '')
                this.loadingHighLights = false;
                // await this.typeWriter(this.highLights)
            }
            this.lastUpdatedAtHighLights = moment(Date.now()).format("DD MMM,YYYY")
            this.disableHightLighBtn = false;


        },

        convertTo6x2(originalData) {
            const newData = [];
            let arrToadd = []
            for (let i = 0; i < originalData.length; i++) {
                const row = originalData[i].appeq_summary_row;
                const newRow1 = row.slice(0, 2);
                arrToadd.push(...row.slice(2, 4));
                if (arrToadd.length == 2) {
                    newData.push({ appeq_summary_row: arrToadd });
                    arrToadd = []
                }
                newData.push({ appeq_summary_row: newRow1 });
                // newData.push({ appeq_summary_row: newRow2 });
            }

            return newData;
        },
        async typeWriter() {
            if (this.i < this.highLights.length) {
                document.getElementById("highLights").innerHTML += this.highLights.charAt(this.i);
                this.i++;
                const wait = (ms) => new Promise((res) => setTimeout(res, ms))
                await wait(20);
                return await this.typeWriter()
            }

        },
        getRandomColor() {
            let maxVal = 0xFFFFFF; // 16777215
            let randomNumber = Math.random() * maxVal;
            randomNumber = Math.floor(randomNumber);
            randomNumber = randomNumber.toString(16);
            let randColor = randomNumber.padStart(6, 0);
            return `#${randColor.toUpperCase()}`
        },
        openCompose(index, data) {
            window.open(`https://mail.google.com/mail/u/0/?view=cm&fs=1&to=${data.appeq_emails}&tf=1`)
        },
        openLink(link) {
            console.log(link)
            window.open(link, "_blank")

        },
        convertToDate(value, column) {
            if (typeof value === "string" && moment(value, moment.ISO_8601, true).isValid() && isNaN(value)) {
                let temp = new Date(value);
                var day = temp.getUTCDate();
                var month = temp.toLocaleString('default', { month: 'short' });
                var year = temp.getUTCFullYear();
                var formattedDateString = day + ' ' + month + ' ' + year;
                return formattedDateString;
            }
            console.log(value)
            return value ? value.toString().substr(0, 18) : "NA";
        },
        async openSummary(ID, mode, selectedSum) {
        console.log("Summary has started3")
            
            if (this.account_domain) {
                try {

                    localStorage.setItem("__email__id",this.email);
                    let res = await ApiServer.getSupportedApps();
                    localStorage.setItem("features",JSON.stringify(res.data.configs.features))
                    let features = localStorage.getItem("features")
                    if (features && JSON.parse(features)) {
                        features = JSON.parse(features)
                        features.map(data => {
                            if (data && data.feature_name == "appeqSlack" && data.enabled == false) {
                                this.slackEnabled = false
                            }
                            if (data && data.feature_name == "appeqNotes" && data.enabled == false) {
                                this.notesEnabled = false
                            }
                            if (data && data.feature_name == "appeqGenie" && data.enabled == false) {
                                this.genieEnabled = false
                            }
                            if (data && data.feature_name == "appeqTrendsAndDashboard" && data.enabled == true) {
                                this.dashboard = true;
                                this.metabaseNumber = data.dashboardNum
                            }
                        })
                    }
                    this.loadingSum = true;

                    const crunchbaseData = (await ApiServer.getCrunchbaseData(this.account_domain)).data
                    if (crunchbaseData.code == 500) {
                        console.log('code 500')
                        appeqAnalytics.actionCall("ThirdPartyDataNotFound", "Summary", { accountDomain: this.account_domain });
                    }
                    if (crunchbaseData && crunchbaseData.data)
                        this.appeq_supported_application = crunchbaseData.data.appeq_supported_application
                    if (this.summaryIds.length == 0) {
                        const ids = await ApiServer.getSummaryIds(this.account_domain,this.email)
                        console.log(ids,"1234")
                        console.log("!23")
                        if (ids.code == 200) {
                            if (Array.isArray(ids.data)) {
                                if (ids.data.length == 0) {
                                    this.summaryData = [
                                        {
                                            appeq_summary_row: [
                                                {
                                                    appeq_summary_column: {
                                                        appeq_summary_label: "Org Linkedin",
                                                        appeq_summary_value: "No Data",
                                                        appeq_summary_type: "crunchbase",
                                                        query: "{}",
                                                    },
                                                },
                                                {
                                                    appeq_summary_column: {
                                                        appeq_summary_label: "Website",
                                                        appeq_summary_value:
                                                            "No Data",
                                                        appeq_summary_type: "crunchbase",
                                                        query: '{"$or":[{"priority":"high"},{"priority":"urgent"}]}',
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            appeq_summary_row: [
                                                {
                                                    appeq_summary_column: {
                                                        appeq_summary_label: "Region",
                                                        appeq_summary_value:
                                                            "No Data",
                                                        appeq_summary_type: "crunchbase",
                                                        query: '{"$or":[{"priority":"high"},{"priority":"urgent"}]}',
                                                    },
                                                },

                                            ],
                                        },


                                    ]
                                    await Promise.all(this.summaryData.map(res => {
                                        res.appeq_summary_row.map(d => {
                                            if (d.appeq_summary_column.appeq_summary_type == 'crunchbase') {
                                                if (d.appeq_summary_column.appeq_summary_label == 'Org Linkedin' && crunchbaseData.data && crunchbaseData.data.data && crunchbaseData.data.data.linkedin_url) {
                                                    d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.linkedin_url
                                                }
                                                if (d.appeq_summary_column.appeq_summary_label == 'Region' && crunchbaseData.data && crunchbaseData.data.data && crunchbaseData.data.data.city) {
                                                    d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.city
                                                }
                                                if (d.appeq_summary_column.appeq_summary_label == 'Website' && crunchbaseData.data && crunchbaseData.data.data && crunchbaseData.data.data.homepage_url) {
                                                    d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.homepage_url
                                                }
                                                if (d.appeq_summary_column.appeq_summary_label == 'About' && crunchbaseData.data && crunchbaseData.data.data && crunchbaseData.data.data.short_description) {
                                                    d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.short_description
                                                }
                                            }
                                        })

                                    }))
                                    this.loadingSum = false;
                                }
                                console.log(ids)
                                this.summaryIds = ids.data
                                if (this.summaryIds.length == 0) {
                                    this.loadingSum = false;
                                    this.loadingTrends = false;
                                    return;
                                }
                                if (mode == true) {
                                    this.selectedIds = ID;
                                    this.selectedDom = selectedSum
                                } else {
                                    this.selectedIds = this.summaryIds[0].id
                                    this.selectedDom = this.summaryIds[0]

                                }
                            } else {

                            }

                        }
                    }
                    if (mode == true) {
                        this.selectedIds = ID;
                        this.selectedDom = selectedSum
                    } else {
                        this.selectedIds = this.summaryIds[0].id
                        this.selectedDom = this.summaryIds[0]

                    }
                    console.log(this.selectedIds)
                    const email = this.email;
                    let data = (await ApiServer.fetchSummaryData(this.account_domain, this.selectedIds))

                    if (data && data.length == 0) {
                        this.noDataFound = true;
                        this.loadingSum = false;
                        return;
                    }


                    console.log(data)
                    if (data.data && data.data[0].ai) {
                        this.showHighBtn = true;
                    }

                    // check for members for which summary to be shown
                    if (data && data.data && data.data[0].data)
                        this.summaryData = data.data[0].data
                    else {
                        this.noDataFound = true;
                        this.loadingSum = false;
                        return;
                    }

                    // if(this.summaryData.length!=0){
                    //     if(this.summaryData[0]['appeq_summary_row'].length>=3){
                    //         this.summaryData.push({
                    //             appeq_summary_row:[this.summaryData[0]['appeq_summary_row'][2]]
                    //         })
                    //         // delete this.summaryData[0]['appeq_summary_row'][2]
                    //     }
                    // }
                    const newData = this.convertTo6x2(this.summaryData)
                    this.summaryData = newData
                    // this.summaryData = data.data.data[0].data
                    await Promise.all(this.summaryData.map(async res => {

                        if (res.appeq_summary_row) {
                            await Promise.all(res.appeq_summary_row.map(async d => {

                                if (d.appeq_summary_column.appeq_summary_value) {
                                    let val = d.appeq_summary_column.appeq_summary_value
                                    let funcName = val.toString().substr(0, val.toString().indexOf("("));
                                    val = val.toString().substr(val.toString().indexOf("("));

                                    // d.appeq_summary_column.appeq_summary_bObj = val.split(",")[0].replace("(", "")
                                    if (d.appeq_summary_column.appeq_summary_bObj == 'Crunchbase Data') {
                                        if (val.split(",")[1].replace(")", "").includes('linkedin')) {
                                            if (crunchbaseData.data && crunchbaseData.data.data)
                                                d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.linkedin_url
                                            else
                                                d.appeq_summary_column.appeq_summary_value = "No Data"

                                        } else if (val.split(",")[1].replace(")", "").includes('website_url')) {
                                            d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.homepage_url

                                        } else if (val.split(",")[1].replace(")", "").includes('short_description')) {
                                            d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.short_description

                                        } else {
                                            d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.region

                                        }
                                        return;
                                    }

                                    // d.appeq_summary_column.appeq_summary_value = (await ApiServer.fetchSummaryDataObj(this.account_domain, val.split(",")[0].replace("(", ""), funcName, val.split(",")[1].replace(")", ""))).data
                                    if (d.appeq_summary_column.appeq_summary_value.error) {
                                        d.appeq_summary_column.appeq_summary_value = 'No Data'
                                        d.appeq_summary_column.appeq_summary_type = ''
                                        return
                                    }
                                    const link = d.appeq_summary_column.appeq_summary_value.link
                                    console.log("Link is " + link)
                                    d.appeq_summary_column.appeq_summary_value = d.appeq_summary_column.appeq_summary_value.data
                                    if (link) {
                                        d.appeq_summary_column.appeq_summary_value += " ?"
                                        d.appeq_summary_column.appeq_summary_type = "link"

                                    }

                                    if (d.appeq_summary_column.appeq_summary_type == 'graph' && !d.appeq_summary_column.appeq_summary_value.error && Array.isArray(d.appeq_summary_column.appeq_summary_value)) {
                                        this.lineData['labels'] = d.appeq_summary_column.appeq_summary_value.map(data => data.date)
                                        this.lineData['datasets'] = [{ 'data': d.appeq_summary_column.appeq_summary_value.map(data => data.tickets) }]
                                        const ticketsData = d.appeq_summary_column.appeq_summary_value.map(data => {
                                            return { time: data.date.toString(), value: data.tickets }
                                        })
                                        this.lineData['data'] = ticketsData
                                        return;
                                    }
                                    if (d.appeq_summary_column.appeq_summary_type == 'bargraph' && !d.appeq_summary_column.appeq_summary_value.error && Array.isArray(d.appeq_summary_column.appeq_summary_value)) {
                                        console.log(d.appeq_summary_column.appeq_summary_value, "---bar")
                                        if (d.appeq_summary_column.appeq_summary_value.length == 0) {
                                            return;
                                        }
                                        this.barData['labels'] = d.appeq_summary_column.appeq_summary_value.map(data => data._id.day + "/" + data._id.month + "/" + data._id.year)
                                        this.barData['datasets'] = [{ 'data': d.appeq_summary_column.appeq_summary_value.map(data => data.count) }]
                                        const barData = d.appeq_summary_column.appeq_summary_value.map(data => {
                                            return { time: data._id.day + "/" + data._id.month + "/" + data._id.year, value: data.count }
                                        })
                                        this.barData['data'] = barData
                                        return;
                                    }
                                    if (d.appeq_summary_column.appeq_summary_type == 'lastTicket' && d.appeq_summary_column.appeq_summary_value && d.appeq_summary_column.appeq_summary_value['created_at']) {
                                        let date1 = new Date()
                                        var x = new moment(d.appeq_summary_column.appeq_summary_value && d.appeq_summary_column.appeq_summary_value['created_at'] ? d.appeq_summary_column.appeq_summary_value['created_at'] : null)
                                        var y = new moment(date1)
                                        var duration = moment.duration(x.diff(y))
                                        let duration_days = Math.abs(Math.floor(duration.asDays()))
                                        d.appeq_summary_column.appeq_summary_prevalue = duration_days + ' days ago by '
                                        d.appeq_summary_column.query = { 'id': d.appeq_summary_column.appeq_summary_value['id'] }
                                        d.appeq_summary_column.appeq_emails = d.appeq_summary_column.appeq_summary_value['requester_email']
                                        d.appeq_summary_column.appeq_summary_value = d.appeq_summary_column.appeq_summary_value['requester_email'].toString().split("@")[0][0]
                                    }
                                    if (d.appeq_summary_column.appeq_summary_type == 'requesters' && Array.isArray(d.appeq_summary_column.appeq_summary_value)) {
                                        d.appeq_summary_column.appeq_emails = d.appeq_summary_column.appeq_summary_value
                                        d.appeq_summary_column.appeq_summary_value = d.appeq_summary_column.appeq_summary_value.map(data => data.toString().split("@")[0].split(".").map(d => d[0]).join(""))
                                    }
                                    d.appeq_summary_column.appeq_summary_value = typeof d.appeq_summary_column.appeq_summary_value == "object" && d.appeq_summary_column.appeq_summary_type != "requesters" ? "_" : d.appeq_summary_column.appeq_summary_value;
                                    if (d.appeq_summary_column.appeq_summary_label == 'Health') {
                                        d.appeq_summary_column.appeq_summary_value = this.health;
                                        console.log("health of it is", this.health);
                                    }
                                }

                            })
                            )
                        }
                    }))
                    if (data.data && data.data[0].third_party_data) {
                        const newsummData = [
                            {
                                appeq_summary_row: [
                                    {
                                        appeq_summary_column: {
                                            appeq_summary_label: "Org Linkedin",
                                            appeq_summary_value: "No Data",
                                            appeq_summary_type: "crunchbase",
                                            query: "{}",
                                        },
                                    },
                                    {
                                        appeq_summary_column: {
                                            appeq_summary_label: "Website",
                                            appeq_summary_value:
                                                "No Data",
                                            appeq_summary_type: "crunchbase",
                                            query: '{"$or":[{"priority":"high"},{"priority":"urgent"}]}',
                                        },
                                    },
                                ],
                            },
                            {
                                appeq_summary_row: [
                                    {
                                        appeq_summary_column: {
                                            appeq_summary_label: "Region",
                                            appeq_summary_value:
                                                "No Data",
                                            appeq_summary_type: "crunchbase",
                                            query: '{"$or":[{"priority":"high"},{"priority":"urgent"}]}',
                                        },
                                    },

                                ],
                            },


                        ]
                        await Promise.all(newsummData.map(res => {
                            res.appeq_summary_row.map(d => {
                                if (d.appeq_summary_column.appeq_summary_type == 'crunchbase') {
                                    if (d.appeq_summary_column.appeq_summary_label == 'Org Linkedin' && crunchbaseData.data && crunchbaseData.data.data && crunchbaseData.data.data.linkedin_url) {
                                        d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.linkedin_url
                                    }
                                    if (d.appeq_summary_column.appeq_summary_label == 'Region' && crunchbaseData.data && crunchbaseData.data.data && crunchbaseData.data.data.city && crunchbaseData.data.data.region) {
                                        d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.city + "," + crunchbaseData.data.data.region
                                    }
                                    if (d.appeq_summary_column.appeq_summary_label == 'Website' && crunchbaseData.data && crunchbaseData.data.data && crunchbaseData.data.data.homepage_url) {
                                        d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.homepage_url
                                    }
                                    if (d.appeq_summary_column.appeq_summary_label == 'About' && crunchbaseData.data && crunchbaseData.data.data && crunchbaseData.data.data.short_description) {
                                        d.appeq_summary_column.appeq_summary_value = crunchbaseData.data.data.short_description
                                    }
                                }
                            })

                        }))
                        this.summaryData.unshift(...newsummData)
                    }
                    this.loadingSum = false;
                    let trendsFunctions = data.data[0].trends
                    await new Promise((res) => setTimeout(res, 200))
                    let functionName = [];
                    this.openPulseInfo();
                    const lastValue = await ApiServer.getLatestVal(this.selectedIds, this.account_domain)
                    console.log(lastValue, "---1234")
                    if (lastValue && lastValue.data && lastValue.data.length != 0) {
                        this.reason = "Reason : " + lastValue.data[0]['data.reason']
                        this.reasonTime = this.convertToDaysAgo(lastValue.data[0]["data.time"])

                    }
                    if (trendsFunctions && Array.isArray(trendsFunctions) && trendsFunctions.length > 0) {
                        functionName = trendsFunctions;
                        this.trendsValue = true;
                    }
                    else {
                        this.loadingTrends = false;
                        this.trendsValue = false;
                        return;
                    }
                    let selectKys = functionName.map(d => d.key).filter(d => d != null)
                    this.trends = functionName
                    console.log(this.trends, "---1234")
                    let trendsData = await ApiServer.openTrendsData(this.account_domain, selectKys, this.selectedIds);

                    this.loadingTrends = false;

                    console.log(trendsData);

                    await new Promise((res) => setTimeout(res, 200))
                    if (trendsData.data) {
                        functionName.map((d, idx) => {
                            console.log(d.key)

                            if (d.key != "npsbargraph" && d.key != "npspiechart") {
                                const ticketsData = trendsData.data[idx].map(data => {
                                    if (data.timestamp) {
                                        data.date = new Date(data.timestamp)
                                    }
                                    return { time: data.date.toString(), value: data.tickets ? data.tickets : data.value }
                                })

                                const chart = createChart(document.getElementById("chartDiv" + idx), {
                                    width: 120, height: 80, grid: {
                                        vertLines: {
                                            color: "transparent",
                                            visible: false
                                        },
                                        horzLines: {
                                            color: "transparent"
                                        },

                                    },
                                    leftPriceScale: {
                                        visible: true,
                                    },

                                    timeScale: {
                                        barSpacing: 9,
                                    },

                                });
                                chart.priceScale('left').applyOptions({
                                    autoScale: true,
                                    visible: true,
                                });
                                chart.priceScale('right').applyOptions({
                                    autoScale: true,
                                    visible: false
                                });
                                const lineSeries = chart.addLineSeries();
                                lineSeries.applyOptions({

                                    lineType: 2, lineStyle: 0,
                                    priceScale: {
                                        position: 'left',

                                    },


                                })
                                lineSeries.setData(ticketsData);
                                this.chartDi = chart
                            } else if (d.key == "npspiechart") {
                                console.log(d.key)
                                console.log(trendsData.data[idx])
                                console.log("here123")
                                const arrays = []
                                // const data = [{ value: 1, time: 1642425322 }, { value: 8, time: 1642511722 }, { value: 10, time: 1642598122 }, { value: 20, time: 1642684522 }, { value: 3, time: 1642770922, color: 'red' }, { value: 43, time: 1642857322 }, { value: 41, time: 1642943722, color: 'red' }, { value: 43, time: 1643030122 }, { value: 56, time: 1643116522 }, { value: 46, time: 1643202922, color: 'red' }];

                                const ticketsData = trendsData.data[idx].map(data => {
                                    // if(data.timestamp){
                                    //     data.date=new Date(data.timestamp)
                                    // }
                                    //         return { time: data.date.toString(), value: data.tickets?data.tickets:data.value }
                                    arrays.push({
                                        value: data.countDetractors,
                                        time: 1642425322,
                                        color: "red"
                                    })
                                    arrays.push({
                                        value: data.countPassive,
                                        time: 1642511722,
                                        color: "yellow"

                                    })
                                    arrays.push({
                                        value: data.countPromoters,
                                        time: 1642598122,
                                        color: "green"


                                    })

                                })
                                const chart = createChart(document.getElementById("barDiv"), {
                                    timeScale: {
                                        borderColor: "rgba(197, 203, 206, 0.8)",
                                        timeVisible: false,
                                        secondsVisible: false,
                                        rightBarStaysOnScroll: false,
                                    },
                                    showTooltips:false
                                });
                                
                                chart.addHistogramSeries({
                                    
                                    priceFormat: {
                                        type: 'volume',
                                    },
                                    priceScaleId: '', // set as an overlay by setting a blank priceScaleId

                                }).setData(arrays)
                            }
                        })
                    }
                    // if (this.lineData['data']) {
                    //     const chart = createChart(document.getElementById("chartDiv"), {
                    //         width: 120, height: 120, grid: {
                    //             vertLines: {
                    //                 color: "transparent",
                    //                 visible: false
                    //             },
                    //             horzLines: {
                    //                 color: "transparent"
                    //             },

                    //         },
                    //         leftPriceScale: {
                    //             visible: true,
                    //         },

                    //         timeScale: {
                    //             barSpacing: 9,
                    //         },

                    //     });
                    //     chart.priceScale('left').applyOptions({
                    //         autoScale: true,
                    //         visible: true,
                    //     });
                    //     chart.priceScale('right').applyOptions({
                    //         autoScale: true,
                    //         visible: false
                    //     });
                    //     const lineSeries = chart.addLineSeries();
                    //     lineSeries.applyOptions({

                    //         lineType: 2, lineStyle: 0,
                    //         priceScale: {
                    //             position: 'left',

                    //         },


                    //     })
                    //     lineSeries.setData(this.lineData['data']);
                    //     this.chartDi = chart
                    //     if (this.highLights) {
                    //         this.disableHightLighBtn = true;
                    //         try {
                    //             let box = document.querySelector('#newSummaryDiv');
                    //             let widthh = box.offsetWidth - 10;
                    //             console.log(widthh);

                    //             var element = document.getElementById("highLights");
                    //             console.log(element);

                    //             element.setAttribute("style", `display:block;width:${widthh}px`);
                    //         } catch (e) {
                    //             console.log(e);
                    //         }
                    //         // await this.typeWriter(this.highLights)
                    //         this.disableHightLighBtn = false;
                    //     }
                    // }


                }

                catch (err) {
                    this.loadingSum = false;
                    console.log(err);

                }
            }
        },
        getIcon(context_link) {
            try {
                if (context_link == null)
                    return 'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' + 'salesforce.com' + '&sz=128';
                else
                    return 'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' + context_link + '&sz=128';
            } catch (e) {
                console.log(e);
            }
        },
        closeSumm() {
            try {
                if (this.chartDi)
                    this.chartDi.remove();
                if (this.barDi) {
                    this.barDi.remove();
                }
            } catch (e) {
                console.log(e);
            }

            if (document.getElementById("chartDiv"))
                document.getElementById("chartDiv").remove();
            if (document.getElementById("barDiv"))
                document.getElementById("barDiv").remove();
            window.parent.postMessage({action:"__appeq_close_summary", data:""},"*");
            AppEQAnalytics.actionCall("SummaryClosed", "Inbox", {})


        },
        closeModalSumm() {
            feeds.sendActionToTop("__appeq__close_modal", "");
        },
        handleRedirect(query, val) {
            if (typeof query != 'object') {
                query = JSON.parse(query)
            }
            let final = { ...query, account_domain: this.account_domain, business_object_name: val, account_id: this.selectedIds }
            console.log(final)
            feeds.sendActionToTop("__appeq_open_ticket_iframe_summary", final);
        },
        handleOpenAccount() {
            feeds.sendActionToTop("__appeq_open_account_collabration", { account_domain: this.account_domain, id: this.selectedIds });
        },
        handleOpenLatest() {
            feeds.sendActionToTop("__appeq_open_profile_summary", { account_domain: this.account_domain });
            AppEQAnalytics.actionCall("GenieOpened", "Inbox", { account_domain: this.account_domain })
        },
        openHealthRed() {
            let final = { health: "red", account_domain: this.account_domain, business_object_name: { "$ne": "pulse" } }
            // feeds.sendActionToTop("__appeq_open_ticket_iframe_summary", final);
            // window.postMessage({action:"__appeq_open_ticket_iframe_summary",data:final})
            window.parent.postMessage({action:"__appeq_open_ticket_iframe_summary",data:final},"*")
            // chrome.runtime.sendMessage({action:"__appeq_open_ticket_iframe_summary",data:final}, '')
        },
        getFeedName(name) {
            if (name) {
                let companyName = name;

                try {
                    if (companyName.toString().split(".").length > 2) {
                        companyName = companyName.toString().split(".")[1] + '.' + companyName.toString().split(".")[2];
                    } else if (companyName.toString().split("@").length > 1) {
                        companyName = companyName.toString().split("@")[0];
                    }
                } catch (e) {
                    console.log(e);
                }

                name = companyName;
            }

            return name;
        },
    },
}
</script>

<style scoped lang="css">
@import './Summary.css';

img {
    cursor: pointer;
    height: 9px;
    margin-left: 8px;
    margin-top: 2px;

}

.requesterEmail {
    background-color: green;
    color: #fff;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;
    /* padding: 4px; */
    margin-left: 3px;
    width: 20px;
    display: block;
    text-align: center;
}

.close {
    object-fit: cover;
}

.knowMoreHighLights {
    font-size: 14px;
    font-weight: 600;
    margin-left: -4px;
    cursor: pointer;
    display: flex;
    align-items: center;

}

.knowMoreHighLights img {
    height: 50px;
    margin-top: 0px;
    margin-left: 0px;
}

#highLights {
    font-size: 10px;
    color: rgb(129, 127, 127);
}

.loadingHighLights {
    height: 40px;
    width: 40px;
}

.loadingHigh {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.no_data_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

[role~=tooltip][data-microtip-position=right]:after,
[role~=tooltip][data-microtip-position=right]:before {
    left: 100%;
    top: 35%;
}

[aria-label][role~=tooltip]:after {
    padding: 0.5em 0.5em;
}

.tabs {
    width: 420px;
    font-size: 12px !important;
    overflow: scroll;
    overflow-y: hidden;
}

.tabs::-webkit-scrollbar-thumb {
    display: none;
    width: 0px;
}

.tabs::-webkit-scrollbar {
    width: 0px;
    display: none;
}

.tab {
    text-wrap: nowrap;
    font-size: 12px !important;
}

.row {
    display: flex;
    align-items: flex-start;
    overflow: hidden;

}

.ticket_info_body {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    border-right: 1px solid rgb(118 129 237 0.5);
    /* width: 75%; */
    /* flex: 0 0 auto; */
    max-width: 75%;
    min-height: 18vh;
}

.trends {
    border-top: 1px solid lightgrey;
    padding-top: 8px;
}

.multiselect_notCustomer {
    .multiselect {
        width: 4rem !important;
    }

    .multiselect__tags {
        width: 4rem !important;
    }
}

.options {
    font-size: 10px;
    margin-top: 10px;
    cursor: pointer;
}

.healthSpan {
    cursor: pointer !important;
}
.row{
    margin-left: 0px !important;
}
</style>
